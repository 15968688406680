import React from 'react';
import Link from 'components/commercetools-ui/atoms/link';
import Typography from 'components/commercetools-ui/atoms/typography';
import { Reference } from 'types/reference';

export interface FooterLink {
  name: string;
  reference: Reference;
  icon: string;
}

export interface Props {
  header?: string;
  links: FooterLink[];
  className?: string;
}

const Column: React.FC<Props> = ({ header, links, className }) => {
  return (
    <div className={className}>
      {header && (
        <Typography fontSize={16} medium className="uppercase sm:pb-20">
          {header}
        </Typography>
      )}
      <ul className="mb-3 flex flex-col items-start gap-y-18 self-start">
        {links.map((item, i) => (
          <li key={i}>
            <Link link={item.reference} className={`${className} hover:opacity-50`}>
              <div className="flex items-center">
                <Typography fontSize={14} fontFamily="primaryThin" className={className}>
                  {item.name}
                </Typography>
                {item.icon && <img src={item.icon} alt={item.name} className="ml-4" />}
              </div>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Column;
