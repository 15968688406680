import React, { FC } from 'react';
import Link from 'components/commercetools-ui/atoms/link';
import Typography from 'components/commercetools-ui/atoms/typography';
import { useFormat } from 'helpers/hooks/useFormat';
import Image, { NextFrontasticImage } from 'frontastic/lib/image';
import { SocialMedia } from '.';

interface Props {
  logo?: NextFrontasticImage;
  socialMedia?: SocialMedia[];
}

const FooterBottom: FC<Props> = ({ logo, socialMedia }) => {
  const { formatMessage } = useFormat({ name: 'common' });
  return (
    <>
      <div className="flex flex-col items-center py-32">
        <ul className="mb-20 flex flex-row gap-20 self-center">
          {socialMedia?.map((item, i) => (
            <li key={i} className="w-22">
              <Link link={item.reference} className="cursor-pointer">
                <img src={item?.logo?.media?.file} className="mb-16" />
              </Link>
            </li>
          ))}
        </ul>
        <Typography fontFamily="primaryThin" fontSize={14} className="text-center">
          {`${formatMessage({
            id: 'copyright',
            defaultMessage: 'COPYRIGHT',
          })} ${new Date().getFullYear()} ${formatMessage({
            id: 'powered',
            defaultMessage: ' CHIPOTLE MEXICAN GRILL INC., LOOMSTATE LLC',
          })}`}
        </Typography>
      </div>
    </>
  );
};

export default FooterBottom;
