import React from 'react';
import Hero from 'components/commercetools-ui/organisms/content/hero';
import { formatCustomerGroup } from 'helpers/formatter';

interface Props {
  data: any;
  customerGroup: any;
}

const ComposedHeroTastic = ({ data, customerGroup }: Props) => {
  if (!data) {
    return <div>Loading...</div>;
  }

  const heros = data?.data?.dataSource?.items?.[0]?.fields?.hero;

  const formattedCustomerGroup = formatCustomerGroup(customerGroup?.obj?.key!);

  const filteredData = heros?.filter((item: { metadata: { tags: any[] } }) =>
    item?.metadata?.tags?.some((tag) => tag.sys.id === formattedCustomerGroup || tag.sys.id === 'allUsers'),
  );

  const isSingleHero = filteredData?.length === 1;

  return (
    <>
      <div className={`grid ${isSingleHero ? 'grid-cols-1' : 'grid-cols-1 gap-4 lg:grid-cols-2'}`}>
        {filteredData?.map((hero: any, index: number) => (
          <div key={index} className="col-span-1">
            <Hero
              image={hero.fields.image.fields.file.url}
              title={hero.fields.title}
              subtitle={hero.fields.subtitle}
              ctaLabel={hero.fields.ctaLabel}
              ctaReference={hero.fields.ctaReference}
            />
          </div>
        ))}
      </div>
    </>
  );
};

export default ComposedHeroTastic;
